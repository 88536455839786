import "../styles/Viewer.scss";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Model from "./model";

type SceneProps = {
  id: string;
  screenshot: boolean;
};

export default function Scene({ id, screenshot }: SceneProps) {
  return (
    <div className="model">
      <Canvas linear flat gl={{ preserveDrawingBuffer: true }}>
        <OrbitControls makeDefault enablePan={false} enableZoom={false} />
        <PerspectiveCamera makeDefault position={[0.5, 0.75, -1]} />
        <ambientLight intensity={2.45} />
        <directionalLight intensity={0.75} position={[20, 20, 20]} />
        <directionalLight intensity={0.75} position={[-20, 20, -220]} />
        {id && (
          <Model
            url={`https://doodlebuild-models.s3.amazonaws.com/${id}.glb`}
            screenshot={screenshot}
          />
        )}
      </Canvas>
    </div>
  );
}
