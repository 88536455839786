import { useParams, useLocation } from "react-router-dom";
import Scene from "../components/scene";
import React from "react";
import "../styles/Viewer.scss";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Viewer() {
  const { id } = useParams();
  const query = useQuery();

  return (
    <main className="background">
      <div className="topCloud">
        <img src={"/modelViewer/cloud.png"} width={200} height={130} alt="" />
      </div>

      <div className="bottomCloud">
        <img src={"/modelViewer/cloud.png"} width={200} height={130} alt="" />
      </div>

      <div className="topBar">
        <img
          src={"/modelViewer/logo2.png"}
          width={100}
          height={75}
          alt="doodlebuild"
        />
      </div>

      <Scene
        id={id as string}
        screenshot={(query.get("screenshot") as string) === "1"}
      />

      <div className="bottomBar">
        <button
          onClick={() => {
            if (navigator.share) {
              navigator
                .share({
                  title: "DoodleBuild Creation!",
                  text: "Check it out!",
                  url: window.location.href,
                })
                .then(() => console.log("Successful share"))
                .catch((error) => console.log("Error sharing", error));
            } else {
              alert("Share only works over HTTPS!");
            }
          }}
        >
          <img
            src={"/modelViewer/share.png"}
            width={175}
            height={70}
            alt="share"
          />
        </button>
      </div>
    </main>
  );
}
