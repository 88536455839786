/* eslint-disable react-hooks/exhaustive-deps */
import { Bounds, Center, useGLTF } from "@react-three/drei";
import { Suspense, useEffect, useRef } from "react";
import TWEEN from "@tweenjs/tween.js";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

type ModelProps = {
  url: string;
  screenshot: boolean;
};

export default function Model({ url, screenshot }: ModelProps) {
  const gltf = useGLTF(url);
  const model = useRef<THREE.Group>();
  const scaler = useRef<any>();

  useFrame(() => {
    TWEEN.update();
  });

  useEffect(() => {
    if (!screenshot) {
      setTimeout(() => {
        const scale = { scale: 0 };
        new TWEEN.Tween(scale)
          .to({ scale: 1 }, 200)
          .easing(TWEEN.Easing.Back.Out)
          .onUpdate(({ scale }) => {
            if (scaler.current) {
              scaler.current.scale.set(scale, scale, scale);
            }
          })
          .onStart(() => {
            if (model.current) {
              model.current.visible = true;
            }
          })
          .start();
      }, 200);
    }
  }, [gltf.scene]);

  useEffect(() => {
    const canvas = document.querySelector("canvas");
    console.log(canvas);
    if (canvas) {
      canvas.id = "canvas";
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Center ref={scaler}>
        <Bounds fit clip observe maxDuration={0} margin={1.2}>
          <primitive object={gltf.scene} ref={model} visible={screenshot} />
        </Bounds>
      </Center>
    </Suspense>
  );
}
